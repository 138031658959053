<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  created() {
    const user = this.$store.getters.user
    if (user.role === 'super_admin') {
      this.$router.push('/companies')
    } else if (user.role === 'company_admin') {
      this.$router.push('/map')
    } else if (user.role === 'driver') {
      this.$router.push('/map')
    } else if (user.role === 'customer') {
      this.$router.push('/public/orders')
    }
  }
}
</script>
